import React from 'react'

function Header () {
    return (
        <div className='p-5 bg-primary flex justify-between'>
            <h1 className='text-secondary text-4xl font-semibold'>S</h1>
            <h1 className='text-white text-4xl font-semibold'>D</h1>
            <h1 className='text-tertiary text-4xl font-semibold'>P</h1>
        </div>
    )
}

export default Header;